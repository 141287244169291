@import "~scss/imports";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  display: flex;
  align-items: center;
  justify-content: center;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 43, 0, 0.75);
  z-index: -1;
}

.frame {
  margin: 20px;
  max-width: var(--inline-photo-max-width);
  padding: 8px;
  position: relative;

  @media (min-width: 768px) {
    margin: 48px auto 0px auto;
  }
}

.inner {
  background-color: var(--green);
  border-radius: var(--corner-radius);
  color: var(--white);
  padding: 24px;

  a {
    justify-self: flex-start;
    color: var(--white);
    text-decoration: none;
  }

 @media (min-width: 768px) {
   padding: 48px 56px 48px 56px;
 }
}

.content {
  display: grid;
  grid-gap: 18px;
  padding: 18px 0;

  @media (min-width: 768px) {
    padding: 36px 0;
    grid-gap: 36px;
  }
}

.heading {
  @extend %h1;
  margin-bottom: 0;
}

.body {
  @extend %body;
}

.image {
  display: block;
  margin: 0;
  max-width: 100%;
  position: relative;
  width: 100%;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.close {
  @extend %mobile-nav-links;
  background-color: transparent;
  border: 0;
  color: var(--white);
  padding: 0;
  display: block;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.center {
  text-align: center;
}