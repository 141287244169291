@import "~scss/imports";

.header {
  @extend %h1;
  color: var(--dark-green);
}

.element {
  margin-top: 48px;
  @media (min-width: 992px) {
    margin-top: 96px;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-top: 48px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;

  }
  @media (min-width: 992px) {
    margin-top: 96px;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.column {
  margin-bottom: 2rem;
}

.bold {
  @extend %smallTileHeadline;
  color: var(--dark-green);
  display: block;
  margin: 0 !important;

  &:hover {
    text-decoration: underline;
  }

  // @media (min-width: 992px) {
  //   margin-bottom: 96px;
  // }
}

.plain {
  @extend %body;
  display: block;
  margin: 0 !important;
  max-width: 350px;
  color: var(--dark-green);
  &:hover {
    text-decoration: underline;
  }
}

.listSmall {
  list-style: none;
  padding-left: 0.1rem;
  margin-top: 1rem;
}