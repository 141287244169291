.partnerAccordionBlock {
  padding-top: 24px;
  padding-bottom: 48px;

  .accordionItem button{
    background-color: transparent;
  }

  .accordionSvg {
    rect{
      fill: none;
    }
  }
}
