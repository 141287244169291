@import "~scss/imports";

.element {
  padding: 48px 24px;
  color: var(--dark-green);

  > div {
    max-width: var(--body-max-width);
    margin: 0 auto;
  }

  h2 {
    @extend %h2;
  }

  h3 {
    @extend %h3;
  }

  p {
    @extend %body;
    max-width: var(--body-max-width);
  }

  @media (min-width: 992px) {
    padding: 96px 104px;
  }
}

.heading {
  color: currentColor;
  margin-bottom: 1rem;
  text-align: center;
}