@import "~scss/imports";


.searchPageWrapper {
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
    padding: 48px 24px 48px 24px;

    h1 {
        @extend %h1;
        color: var(--green);
    }
}

.searchForm {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--green);
}

.searchBtn {
    @extend %focusBlue;
    background-color: transparent;
    padding: 0px;
    border: none;
    cursor: pointer;

    svg * {
      stroke-width: 2px;
      stroke: var(--green);
    }
}

.searchInput {
    @extend %body;
    @extend %focusBlue;
    border: none;
    color: var(--dark-green);
    width: 100%;
}

.searchInput::placeholder {
    color: var(--green);
    opacity: .6;
}

.resultsWrapper {
    display: flex;
    flex-direction: column;

    h3 {
        @extend %smallTileHeadline;
        color: var(--green);

    }

    p {
        @extend %body;
        color: var(--dark-green);
        margin-top: 8px;
    }

    a {
        margin-top: 48px;
        text-decoration: none;
        @extend %focusBlue;
    }
}

.noResultsText {
    margin-top: 48px;
}

.seeMoreBtn {
    @extend %secondary-button-text;
    @extend %focusBlue;
    background-color: transparent;
    border: 2px solid var(--green);
    height: var(--secondary-button-height);
    color: var(--green);
    margin-top: 48px;
    cursor: pointer;
    transition: background .3s;
}

.seeMoreBtn:hover {
    color: var(--white);
    background: var(--green);
}

/* clears the ‘X’ from Internet Explorer */
.searchInput[type=search]::-ms-clear { display: none; width : 0; height: 0; }
.searchInput[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

/* clears the ‘X’ from Chrome */
.searchInput[type="search"]::-webkit-search-decoration,
.searchInput[type="search"]::-webkit-search-cancel-button,
.searchInput[type="search"]::-webkit-search-results-button,
.searchInput[type="search"]::-webkit-search-results-decoration { display: none; }

@media (min-width: 768px) {
    .seeMoreBtn {
        width: var(--secondary-button-width);
        margin-top: 96px;
        margin-left: auto;
        margin-right: auto;
    }

    .searchPageWrapper {
      padding: 112px 104px;
  }

}

@media (min-width: 992px) {

    .searchForm {
        margin-top: 66px;
    }

    .resultsWrapper {

        p {
            margin-top: 16px;
        }

        a {
            margin-top: 96px;
            color: var(--green);
        }
    }

    .noResultsText {
        margin-top: 96px;
    }
}