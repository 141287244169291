@import "~scss/imports";

.notFoundPage {
    padding-top: 200px;
    padding-bottom: 328px;

    h1 {
      @extend %bg-header;
      text-align: center;
    }

}