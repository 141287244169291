@import "~scss/imports";

.fullPagePhotoWrapper {
    padding: 48px 24px;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.fullPagePhotoWrapper > :first-child {
    margin-top: 0px;
}

.noImageBorder {
    max-width: var(--bg-photo-max-width);
    margin: 48px auto 0px auto;
}

.imageBorder {
    display: block;
    margin: 48px auto 0px auto;
    position: relative;
    max-width: var(--bg-photo-max-width);
    border-radius: var(--corner-radius);
    overflow: hidden;
}

.fullPageSubheader {
    color: var(--dark-green);
    max-width: var(--introBody-max-width);
    margin: 48px auto 0 auto;
    @extend %subheader;
}

.header {
    @extend %bg-header;
    max-width: var(--introBody-max-width);
    margin-top: 48px;
}

.introBody {
    @extend %introBody;
    max-width: var(--introBody-max-width);
    margin: 16px auto 0 auto;
    color: var(--dark-green);
}

.body {
    max-width: var(--introBody-max-width);
    margin: 16px auto 0 auto;
}

.cta {
    display: flex;
    justify-content: center;
    margin-top: 48px;
}



// Desktop specific styling
@media (min-width: 992px) {

    .fullPagePhotoWrapper {
        padding: 96px 104px;
    }

    .fullPagePhotoWrapper > .noImageBorder + .body,
    .fullPagePhotoWrapper > .imageBorder + .body {
        margin-top: 64px;
    }

    .body,
    .introBody {
        margin-top: 32px;
    }

    .header,
    .fullPageSubheader,
    .imageBorder,
    .noImageBorder,
    .cta {
        margin-top: 96px;
    }

}
