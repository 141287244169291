@import "~scss/imports";

.accordionMatrixWrapper {
    background-color: var(--white);
    padding: 48px 24px;
    max-width: var(--header-max-width);
    margin: 0 auto;

    img {
        width: 100%;
        height: auto;
    }
}

.accordionMatrixCta {
    margin: 48px auto 0px auto;
}

.inlineImgNoBorder {
    max-width: var(--inline-photo-max-width);
    margin: 0px auto 0px auto;
}

.inlineImgBorder {
    display: block;
    margin: 0px auto 0px auto;
    padding: 8px;
    position: relative;
    max-width: var(--inline-photo-max-width);

    &:before {
        position: absolute;
        content: '';
        border-top: 2px solid var(--dark-green);
        border-bottom: 2px solid var(--dark-green);
        right: 8px;
        left: 8px;
        top: 0;
        bottom: 0;
    }

    &:after {
        position: absolute;
        content: '';
        border-left: 2px solid var(--dark-green);
        border-right: 2px solid var(--dark-green);
        top: 8px;
        bottom: 8px;
        left: 0;
        right: 0;
    }
}

.accordionHeading {
    // padding-top: 48px;
    @extend %bg-header;
}

.accordionSubheader {
    color: var(--dark-green);
    padding-top: 48px;
    @extend %subheader;
}

.accordionBody {
    color: var(--dark-green);
    padding-top: 8px;
    @extend %body;
}

.accordionBlock {
    padding-top: 32px;
}

.accordionItem.closed + .accordionItem.closed {

    button {
        border-top: none;
    }
}

.accordionItem.closed + .accordionItem.open {
    button.open{
        border-top: none;
    }
}

.accordionItem {
  overflow: hidden;
  transition: height 0.3s;

    .title {
        @extend %bodyBold;
        color: var(--dark-green);
        text-align: left;
    }

    button {
        @extend %focusBlue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: var(--white);
        border: none;
        padding: 16px 0px 16px 0px;
        border-top: 2px solid var(--lime);
        border-bottom: 2px solid var(--lime);
    }

    .contentWrapper {
        padding-top: 36px;
        padding-bottom: 36px;
    }

    .topRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        p {
            color: var(--dark-green);
            @extend %numbers;
        }
    }
}

.accordionSvg {
    margin: 0px 0px 0px 8px;
}

.accordionBtn {
    margin-top: 32px;
}


// Desktop Specific Styling
@media (min-width: 992px) {

    .accordionMatrixWrapper {
        background-color: var(--white);
        padding: 96px 0px;
    }

    .accordionMatrixCta {
        margin: 96px auto 0px auto;
    }


    .accordionHeading,
    .accordionSubheader {
        // padding-top: 96px;
    }

    .inlineImgBorder {
        padding: 15px;

        &:before {
            right: 15px;
            left: 15px;
        }

        &:after {
            top: 15px;
            bottom: 15px;
        }
    }

    .accordionBody {
        padding-top: 26px;
    }

    .accordionItem {

        button {
            padding: 24px 0px 24px 0px;
        }
        .topRow {
            padding-bottom: 22px;
        }
    }

    .accordionBtn {
        margin-top: 40px;
    }

}