@import "~scss/imports";

.emailSuccess{
    @extend %bodySmall;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--dark-green);
    display: flex;
    justify-content: center;

    color: white;
    z-index: 100;
    position: fixed;
}