@import "~scss/imports";

.bodyContactWrapper {
    background: var(--teal);
    padding: 48px 24px;
}

.bodyContactSubheader {
    color: var(--white);
    max-width: var(--body-max-width);
    margin: 0 auto;
    @extend %subheader;
}

.bodyContactSimpleBody {
    max-width: var(--introBody-max-width);
    margin: 8px auto 0px auto;
}

.contactBlocksWrapper {

    padding-top: 48px;

    .contactBlock:first-of-type {
        padding-top: 0px;
    }

    .contactBlock {
        color: var(--white);

        a,
        .contactHeadline,
        .contactBody {
            color: var(--white);
        }
    }
}

@media (min-width: 992px) {

    .bodyContactWrapper {
        padding: 96px 0px;
    }

    .bodyContactSimpleBody {
        margin-top: 32px;
    }

    .contactBlocksWrapper {
        padding-top: 96px;
    }

}