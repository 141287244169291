@import "~scss/imports";

.loadingPage {
  padding-bottom: 328px;
  padding-top: 200px;
}

.loadingPageHeader {
  @extend %bg-header;
  text-align: center;
}