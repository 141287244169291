@import "~scss/imports";

.reportNav {
  display: none;
}

.site-nav {
  background-color: var(--green);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 3;

  &.hasAlert {
    top: var(--alert-height);


    .mobileNav-links {
      padding-top: var(--alert-height);
    }
  }
}

.navItem {
  @extend %focusWhite;
  color: var(--white);
  transition: border-bottom-color .3s;
}


// specifies color of navigation links depending whether hero is present
.heroWrapper {
  display: flex;
  width: 100%;
  z-index: 5;

  .logo {
      @extend %focusWhite;
  }
}

.navInner {
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 24px ;
  transition: height 0.3s;
  // margin: 0 auto;
  // max-width: var(--nav-max-width);
}

.logo {
  display: block;
  z-index: 1;

  svg {
    height: auto;
    max-width: 150px;
  }

  @media (min-width: 1024px) {
    svg {
      max-width: 110px;
    }
  }
}

.mobileNav {
    --toggle-size: 40px;
    position: absolute;
    right: 24px;
}
.mobileNav-toggleph {
    width: var(--toggle-size);
    height: var(--toggle-size);
}

.mobileNavToggle {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    z-index: 1;
    width: var(--toggle-size);
    height: var(--toggle-size);
    padding: 7px;

    .mobileNav.active & {
      position: fixed;
    }
}

.mobileNav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--green);
}

.mobileNav-links {
    @extend %mobile-nav-links;
    list-style-type: none;
    margin: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    margin-top: var(--nav-height);
    padding-inline-start: 0px;

    li:first-child {
      margin-top: 30px;
    }
    li:last-child {
      margin-bottom: auto;
    }

    li + li {
      margin-top: 42px;
    }

    .navItem {
      color: var(--white);
      text-decoration: none;
    }

    a.navItem {
      padding-left: 24px;
    }

  .mobileNav-dropdown-item {
    margin-top: 1rem;
  }

  .mobileNav-dropdown-item:first-child {
    margin-top: 1rem;
  }
}

.listItem.has-children {
  position: relative;

  &:hover {
    .dropdown {
        height: auto;
    }
  }
}

.dropdown-navItem {
  @extend %mobile-nav-links;
  color: var(--white);
  display: block;
  font-weight: var(--regular-weight);
  text-decoration: none;
}

.mobileNav-label {
  background-color: transparent;
  border: 0;
  display: flex;
  font-size: var(--body-semi-size);
  line-height: var(--body-semi-line);
  font-weight: var(--semi-weight);
  padding: 0 0 0 24px;

  svg {
    height: 18px;
    width: 18px;
  }

  rect {
    fill: var(--green);
  }

  path {
    stroke: var(--white);
  }
}

.navItem.active:not(.donateBtn) {
  position: relative;

  &:after {
    background-color: var(--lime);
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.dropdown-wrapper {
  list-style: none;
  min-width: 100%;
  padding: 2px 0;
}

.dropdown {
  align-items: center;
  border-top: 24px solid transparent;
  display: flex;
  left: -24px;
  min-width: 190px;
  overflow: hidden;
  padding: 1rem 0;
  position: absolute;
  right: 0;
  top: 100%;
  // width: 250%;
  z-index: -1;

  &.closed {
    height: 0;
    padding: 0;
  }

  &.open {
    height: auto;
    overflow: visible;

    .dropdown-wrapper {
      background-color: var(--green);
    }
  }
}

.mobileNav-dropdown {
  overflow: hidden;
  transition: height 0.2s;
  padding: 0;

  .dropdown-wrapper {
    background-color: var(--light-green);
    padding-bottom: 1rem;
    margin-top: 1rem;
  }

  .mobileNav-dropdown-navItem {
    color: var(--green);
  }

  &.closed {
    height: 0;
    padding: 0;
  }

  &.open {
    height: auto;
  }
}

.dropdown-item {
  align-self: center;

  a {
    border: 0;
  }

  .dropdown-navItem {
    padding: 1rem 2rem;
    transition: all 0.3s;

    &:hover {
      background-color: var(--white);
      color: var(--green) !important;
    }
  }
}

.mobileNav-dropdown-navItem {
  font-weight: var(--regular-weight);
}

// Top level nav bar styling
.navLinks {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    width: 75%;
    z-index: 1;

    &.widen {
      width: 80%;
    }

    .donateBtn {
      @extend %buttonRound-lime;
    }

    .navItem {
        padding-bottom: 6px;
        @extend %mobile-nav-links;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        border-top: 2px solid transparent;

        &.donateBtn {
          @extend %buttonRound-lime;
        }
    }

    .navItem:first-child {
        margin-left: 0px;
    }
}

button.navItem {
  background-color: transparent;
  box-shadow: 0;
  border-color: transparent;
}

.navItem.searchIcon {
    line-height: 0px;
}

@media (min-width: 992px) {
  .navInner {
    padding: 24px 104px 24px 104px;
  }
}

@media (min-width: 1200px) {
  .navLinks {
    width: 60%;
  }
}
