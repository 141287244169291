@import "~scss/imports";

.primary,
.secondary {
  text-align: center;
  width: 100%;
}

.primary {
  @extend %buttonRound-lime;
  @extend %primary-button-text;
  color: var(--dark-green);
}

.secondary {
  @extend %secondary-button-text;
  @extend %buttonRound-lime;
  color: var(--dark-green);
}


// .primary.white:hover,
// .secondary.white:hover {
//     color: var(--green);
//     background: var(--white);
// }

// .primary.white {
//     color: var(--white);
//     border: 8px solid var(--white);
//     @extend %primary-button-text;
//     @extend %focusWhite;
// }

// .secondary.white {
//   @extend %secondary-button-text;
//   @extend %buttonRound-lime;
//   color: var(--white);
// }

// .primary.blue:hover,
// .secondary.blue:hover {
//     color: var(--white);
//     background: var(--green);
// }

// .primary.blue {
//     // color: var(--green);
//     // border: 8px solid var(--green);
//     // @extend %primary-button-text;
//     // @extend %focusBlue;
//     @extend %buttonRound-lime;
//     color: var(--dark-green);
// }


// .secondary.dark-blue:hover {
//     color: var(--white);
//     background: var(--dark-green);
// }

// .secondary.dark-blue {
//     color: var(--dark-green);
//     border: 2px solid var(--dark-green);
//     @extend %secondary-button-text;
//     @extend %focusBlue;
// }

// .secondary.blue {
//     border: 2px solid var(--green);
//     color: var(--green);
//     @extend %secondary-button-text;
//     @extend %focusBlue;
// }




// Tablet and Up
@media (min-width: 768px) {

    .primary,
    .secondary {
      text-align: left;
      width: auto;
    }

}
