@import "~scss/imports";


footer {
  background: var(--dark-green);

  iframe {
    background: transparent;
  }
}

.footerTop {
  background-color: var(--green);
  padding: 44px 24px 48px 24px;
  // margin: 0 auto;
  // max-width: var(--nav-max-width);
}

.footerBottom {
  padding: 44px 24px 48px 24px;
  grid-gap: 1rem;
//   margin: 0 auto;
//   max-width: var(--nav-max-width);
}

.footerSection {
  display: flex;
  justify-content: space-between;
}

.newsLetterOutputFrame {
  border: none;
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;
  }
}

.newsLetterTitle {
    @extend %bodySmall;
    color: var(--white);
    padding-top: 48px;
}

.newsLetterSection {
    display: flex;
    flex-direction: column;
}

.newsLetterForm {
    flex-direction: column;
    display: flex;
    padding-top: 12px;

    >input {
        @extend %bodySmall;
        box-sizing: border-box;
        background-color: #001F00;
        border-radius: 20px;
        color: var(--white);
        // border: 2px solid var(--white);
        height: 48px;
        padding: 0px 0px 0px 16px;
    }

    button {
        @extend %bodySmall;
        cursor: pointer;
        background-color: #001F00;
        border-radius: 20px;
        color: var(--white);
        height: 48px;
        width: 112px;
        // border: 2px solid var(--white);
        padding: 0px;
        margin-top: 24px;
    }
}

.middleSection {
    width: 200px;
}

.mediaLinks {
    display: flex;
    flex-direction: row;
}

.mediaGrid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    width: 66%;
}

.mediaRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
        &:focus-visible {
            outline: 0px;
            svg {
                outline-color: var(--white);
                outline-offset: 12px;
                outline-style: solid;
                outline-width: 1px;
            }
        }

        &:hover {
          svg * {
            fill: var(--lime);
          }
        }

        &:focus {
            svg {
                outline-offset: 10px;
            }
        }
        padding-right: 50px;
    }

}

.mediaRow:last-of-type {
    a {
        padding-bottom: 0px;
    }
    a:last-of-type {
        padding-right: 0px;
    }
}

.footerLogo {
    padding-top: 7px;
}

.contactInfo {
    padding-left: 45px;
    @extend %bodySmall;
    color: var(--white);
}

.footerLinks {
    margin: 0;
    padding: 0;
}

.footerBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.linkItem {
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  width: 100%;

  &:last-of-type {
    margin: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

.donateLogoSection {
  column-gap: 22px;
  display: flex;
  justify-items: flex-start;

  a {
      @extend %focusWhite;

      &:first-child {
          justify-self: flex-start;
      }

      &:last-child {
          justify-self: flex-end;
      }
  }

  img {
      max-height: 35px;
      width: auto;
      @extend %focusWhite;


      &:first-child {
          justify-self: flex-start;
      }

      &:last-child {
          justify-self: flex-end;
      }
  }

  @media (min-width: 400px) {
    img {
      max-height: 45px;
    }
  }

  @media (min-width: 992px) {
    img {
      max-height: 40px;
    }
  }

  @media (min-width: 1024px) {
      img {
          max-height: 55px;
      }
  }
}

// .footerLinks {}

.addressBlock {
    padding-top: 12px;
    border-top: 2px solid var(--white);
}

.officeName {
    padding-bottom: 12px;
}


// Desktop styling
@media (min-width: 992px) {

    .footerSection {
        padding-bottom: 0px;
        justify-content: initial;
    }

    .newsLetterTitle {
        padding-top: 0px;
    }

    .newsLetterForm {
        flex-direction: row;

        >input {
            height: 56px;
            width: 100%;
        }

        button {
            height: 56px;
            margin-top: 0px;
            margin-left: 16px;
        }
    }

    .footerTop {
      padding: 69px 104px 69px 104px;
    }


    .footerBottom {
        flex-direction: row;
        padding: 96px 104px 47px 104px;
    }

    .contactInfo {
        padding-left: 80px;
    }

    .mediaLinks {
      display: flex;
      flex-direction: column;
    }

    .mediaRow {
        a {
            padding-left: 30px;
            padding-bottom: 30px;
            padding-right: 0px;
        }
    }
}
