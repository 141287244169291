.menuIcon {

    div:first-of-type {
      margin-top: 0px;
    }

    div {
      width: 100%;
      height: 2px;
      background-color: var(--white);
      margin: 5px 0;
      transition: opacity 0.2s, transform 0.3s;
      transform-origin: center center;
    }

    &.active {
      div:nth-child(2), div:nth-child(4) {
        opacity: 0;
      }
      div:nth-child(1) {
        transform: translate(0px, 8px) rotate(45deg);
      }
      div:nth-child(3) {
        transform: translate(0px, -6px) rotate(-45deg);
      }
    }
}

.noHeroWrapper {
    .menuIcon {
        div {
            background-color: var(--green);
        }
    }
}

