@import "~scss/imports";

.navMatrixCards {
  background-color: var(--lime);
  display: flex;
  flex-direction: column;
    padding: 48px 24px;
}

.navMatrixGrid {
  display: grid;
  margin: 0 auto;
  max-width: var(--content-max-width);
  row-gap: 48px;
}

.navCardContainer {
    width: 100%;
    min-height: var(--nav-card-height);
    border: 2px solid var(--white);
    background-color: var(--white);
    box-sizing: border-box;
    position: relative;

    border-radius: var(--corner-radius);
    filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.15));
    // overflow: hidden;
}

.navLinkContainer {
    @extend %focusWhite;
    display: block;
    text-decoration: none;
    height: 100%;

    position: relative;
}

.icon {
  @extend %icon;
  right: 20px;
  top: 20px;

  svg * {
    fill: var(--green);
  }

}

.navCardContent {
  padding: 24px;

  h1 {
    @extend %h2;
    color: var(--dark-green);
    padding-right: 40px;
  }

  p {
    @extend %introBody;
    padding-top: 1rem;
    color: var(--dark-green);
  }
}


@media (min-width: 992px) {
  .icon {
    top: 40px;
    right: 40px;
  }

  .navMatrixCards {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 96px 104px;
  }

  .navMatrixGrid {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 48px;
  }

  .navCardContent {
      padding: 48px 56px 48px 56px;
  }

  .arrow {
      bottom: 28px;
      left: 56px;
  }
}