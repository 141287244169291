@import "~scss/imports";

.featureContainer {
  display: flex;
  justify-content: center;
  padding: 96px 104px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &:global(.home),
  &:global(.whoWeAre),
  &:global(.getInvolved),
  &:global(.findSupport),
  &:global(.donate),
  &:global(.standardWhiteBackground) {
      .featureLink {
          @extend %focusBlue;
      }
  }
}

.icon {
  @extend %icon;
}

.mobileFeatureContainer {
  padding: 48px 24px;
}

.featureLink {
  border-radius: var(--corner-radius);
  display: grid;
  grid-template-columns: 1fr 1fr;
  filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.15));
  overflow: hidden;
  text-decoration: none;
  // max-width: var(--content-max-width);
}

.blogContent {
  background-color: var(--white);
  padding: 30px 40px 28px 56px;
  position: relative;
}

.typeHandle {
    @extend %mobile-nav-links;
    font-weight: var(--semi-weight);
    color: var(--green);
    padding-bottom: 30px;
}

.headline {
    @extend %headline;
    color: var(--dark-green);
    padding-bottom: 24px;
}

.details {
    display: flex;
    border-top: 2px solid var(--lime);
    border-bottom: 2px solid var(--lime);
    min-height: var(--body-small-min-height);
    align-items: center;

    .date + .publisher {
        padding-left: 24px
    }
}

.date,
.publisher,
.summary,
.authors {
    @extend %bodySmall;
    color: var(--dark-green);
}

.date {
    border-right: 2px solid var(--lime);
    padding-right: 22px;
}

.featurePostArrow {
    position: absolute;
    bottom: 28px;
    margin: 0;
}

.authors {
    display: flex;
    border-bottom: 2px solid var(--lime);
    min-height: var(--body-small-min-height);
    align-items: center;
    font-style: italic;
}

.summary {
    padding-top: 24px;
    padding-bottom: 36px;
}

.button {
  @extend %secondary-button-text;
  @extend %buttonRound-lime;
}