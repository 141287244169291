@import "~scss/imports";

.specialReportsNav {
    background-color: var(--green);
    color: var(--white);
    position: fixed;
    // top: 0;
    z-index: 5;
    width: 100%;

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {

    }
}

.reportNavInnerWrapper {
    margin: 0 auto;
    display: flex;
    height: var(--report-nav-height);
    justify-content: space-between;
    align-items: center;

    padding: 0px 24px;

    .reportLogo {
        border-right: 0;
    }

    .navTitle {
        @extend %mobile-nav-links;
        display: none;
    }

    .reportLogoContainer {
        display: flex;
        align-items: center;

        a {
            @extend %focusWhite;
        }
    }

    .dropDownMenu {
        max-height: 0;
        overflow: hidden;
        transition: max-height .15s ease-in-out, padding .15s ease-in-out;
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: var(--corner-radius);
        color: var(--green);
        position: absolute;
        min-width: 150px;
        max-width: 150px;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;

        .menuItem {
            text-decoration: none;
            color: var(--green);
            background-color: var(--white);
            padding: 10px 0px;
            cursor: pointer;

            a {
                @extend %focusBlue;

                &:focus-visible {
                    outline-offset: 8px;
                }
            }
        }
    }

    .dropDownMenu.visible {
        max-height: 100vh;
        transition: max-height .15s ease-in-out, padding .15s ease-in-out;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .dropDownBtn {
        @extend %mobile-nav-links;
        @extend %focusWhite;
        border: 1px solid var(--white);
        border-radius: var(--corner-radius);
        color: white;
        background-color: transparent;
        text-align: left;
        padding: 10px 20px;
        min-width: 150px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        .drownDownCarot {
            margin: 0;
            padding: 0;
            height: 10px;
            display: flex;
            align-self: center;
            transition: transform .15s ease-in-out;
        }

        .drownDownCarot.open {
            transform: rotate(180deg);
        }
    }

    @media (min-width: 600px) {
        .reportLogo {
            border-right: 1px solid var(--white);
            padding-right: 2rem;
        }

        .navTitle {
            padding-left: 2rem;
            display: initial;
        }
    }

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
        padding: 0px 104px;

        .dropDownMenu {
            min-width: 288px;
            max-width: 288px;
        }

        .dropDownBtn {
            min-width: 288px;
        }

    }
}

.reportHeroImg {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: var(--report-nav-height);
    z-index: 0;

    display: grid;

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media (min-width: 768px) {
        img {
            // height: auto;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.reportHeader {
    min-height: 39vh;
    position: relative;
    display: grid;

    .heroHeading {
      @extend %hero-heading;
      color: var(--white);
      max-width: 990px;
      padding-left: 24px;
      padding-bottom: 1rem;
    }

    .content {
      justify-content: flex-end;
      z-index: 2;
    }

    @media (min-width: 768px) {
        min-height: 75vh;
    }

    @media (min-width: 1024px) {
      min-height: 90vh;

      .heroHeading {
        padding-bottom: 2rem;
        padding-left: 104px;
        padding-right: 104px;
      }
    }

    &:before {
      background-color: var(--black);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.4;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
}

.reportIntroNoImage{
    padding-top: 80px;
}

.container {
  padding: 48px 24px;

  > div {
    max-width: var(--introBody-max-width);
    margin: 0 auto;
    padding: 0;
  }

  .cta {
    margin-top: 2rem;
  }

  h3, h4 {
    @extend %h4;
  }

  @media (min-width: 1024px) {
    padding: 96px 104px;
  }
}
