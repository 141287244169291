@import "~scss/imports";

.element {
  img {
    opacity: 0.5;

    &:global(.lazyloaded) {
      animation: fadeIn 1s forwards;
    }
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
