@import "~scss/imports";

.outcomeSectionWrapper {

    background-color: var(--about-secondary);
    padding: 48px 16px 48px 16px;

    h1 {
        max-width: var(--header-bg-max-width);
        @extend %bg-header;
        padding-bottom: 48px;
    }
}

.outcomeSectionInnerWrapper {
    max-width: var(--content-max-width);
    margin: 0 auto;
}

.outcomeBlockWrapper {
    row-gap: 48px;
    display: grid;
}

.outcomeBlock {
    img {
        width: 100%;
        height: auto;
    }
}

.textBlock {
    padding-top: 25px;

    color: var(--dark-green);

    .richTextWrapper {
        padding-top: 8px;
    }

    h3 {
        @extend %subheader;
    }
}


@media (min-width: 768px) and (max-width: 991px) {

    .outcomeBlockWrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 64px 49px;

        h1 {
            padding-bottom: 96px;
        }
    }

}

@media (min-width: 992px) {

    .textBlock {
        .richTextWrapper {
            padding-top: 24px;
        }
    }

    .outcomeSectionWrapper{
        padding: 95px 104px 113px 104px;

        h1 {
            padding-bottom: 96px;
        }
    }

    .outcomeBlockWrapper {
        grid-template-columns: repeat(3, 1fr);
        gap: 64px 49px;
    }

}