@import "~scss/imports";

.leadershipContentWrapper {
    padding: 0px 24px 48px 24px;

    >a {
        text-decoration: none;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.leadershipHead {
  display: grid;
  grid-gap: 50px;
  max-width: var(--introBody-max-width);
  margin: 0 auto;
  padding-top: 50px;


  @media (min-width: 992px) {
    // grid-template-columns: 1fr 1fr;
    padding-top: 0;
  }
}

.leadershipName,
.leadershipTitle {
    color: var(--dark-green);
}

.leadershipBreadcrumb {
  display: flex;
  align-items: center;
  text-decoration: none;
  grid-gap: 1rem;

  &:hover {
    // .leadershipTeamType {
    //   border-color: var(--white);
    // }

    svg {
      left: -30px;
    }
  }

  @media (min-width: 992px) {
    position: relative;

    svg {
      position: absolute;
      left: -24px;
      top: 2px;
      transition: all 0.2s;
    }
  }
}

.leadershipTeamType {
  @extend %breadcrumb;
  color: var(--dark-green);
  display: inline-block;
  border-bottom: 2px solid var(--lime);
  padding-bottom: 4px;
  transition: border 0.2s;
  text-decoration: none;
}

.leadershipName {
    @extend %h1;
    padding-top: 1rem;
}

.leadershipTitle {
  @extend %smallTileHeadline;
  padding-top: 1rem;
  color: var(--green);
}


.leadershipInlineImage {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: var(--inline-photo-max-width);
  border-radius: var(--corner-radius);
  overflow: hidden;
}

.leadershipBodyCopy {
    padding-top: 32px;
    max-width: var(--introBody-max-width);
    margin: 0 auto;
}

@media (min-width: 992px) {

    .leadershipContentWrapper {
        padding: 112px 0px 96px 0px;
    }

    .leadershipName {
    }

    .leadershipBodyCopy {
      padding-top: 50px;
    }
}