@import "~scss/imports";

nav:global(.reportNav) + .alertBar {
  position: sticky;
  top: 0;
  z-index: 9;
}

.alertBar {
  min-height: var(--alert-height);
  background-color: var(--gold);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--dark-green);
  padding: 5px 24px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (min-width: 768px) {
    // justify-content: flex-start;
    padding: 12px 24px;
    grid-gap: 1rem;
  }

  @media (min-width: 992px) {
    padding: 12px 104px 12px 104px;
  }
}

.alertBarLink {
  text-decoration: none;
  color: var(--dark-green);
  @extend %body;
  @extend %focusBlue;
  line-height: 20px;

  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    min-width: 120px;
  }

  svg {
    max-width: 30px;
    transform: translateX(0) rotate(180deg);
    transition: transform 0.2s;
  }

  @media(min-width: 1024px) {
    align-items: center;
    flex-direction: row;

    svg {
      max-width: 40px;
      margin-left: 5px;
    }
  }

  &:hover {
    svg {
      transform: translateX(5px) rotate(180deg);
    }
  }
}

.alertBarText {
  padding-right: 10px;
  @extend %bodyBold;
  line-height: 20px;
}