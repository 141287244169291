@import "~scss/imports";

.leadershipWrapper {
    background-color: var(--light-green);
    color: var(--dark-green);
    padding: 48px 24px 48px 24px;
}

.leadershipInnerWrapper {
    max-width: var(--content-max-width);
    margin: 0 auto;
}

.leadershipHeader {
    @extend %bg-header;
    max-width: var(--header-bg-max-width);
    text-align: center;
}

.leadershipBody {
    @extend %body;
    max-width: var(--body-max-width);
    margin: 0 auto;
    padding-top: 16px;
}

.filterHeader {
    padding-top: 48px;
}

.filterTitle {
    @extend %numbers;
    padding-bottom: 8px;
    // @extend
}

.tabTitles {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    cursor: pointer;
    list-style-type: none;
}

.tabTitle {
  @extend %bodyBold;
  @extend %focusWhite;
  border-top: 2px solid var(--green);
  display: block;
  padding: 1rem 0;
}


.leadershipTile {
    h3 {
        @extend %headline;
    }

    p {
        @extend %body;
    }
}

.tabContent {
  overflow: hidden;
}

.tabInner {
    border-top: 2px solid var(--green);
    display: grid;
    row-gap: 48px;
    margin-top: 1rem;

    a {
        color: var(--dark-green);
        text-decoration: none;
    }
}

.tileTop {
  // border-bottom: 1px solid var(--dark-green);
  display: inline-block;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.tileArrowWrapper {
    max-width: 49px;
    margin: 0px 0px 0px 8px;
}

.tileArrow {
    display: block;
}

.tabBtn {
  @extend %smallTileHeadline;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  font-weight: 600;

  rect {
    fill: none;
  }

  path {
    stroke-width: 2px;
  }
}

// Desktop Specific Styling
@media (min-width: 992px) {

    .leadershipWrapper {
        padding: 96px 104px 96px 104px;
    }

    .leadershipBody {
        padding-top: 32px;
    }

    .filterHeader {
        padding-top: 96px;
    }

    .filterTitle {
        padding-bottom: 17px;
    }

    .tabTitles {
        flex-direction: column;
        margin-right: 0px
    }

    .tabTitle {
        margin-bottom: 0px;
    }

    .tabInner {
        grid-template-columns: repeat(3, 1fr);
        gap: 72px 85px;
        padding: 3rem 0;
    }
}