@import "~scss/imports";


.contactBlock {
    padding-top: 32px;
    max-width: var(--body-max-width);
    margin: 0 auto;
    color: var(--dark-green);

    .contactName {
        @extend %bodyBold;
    }

    .contactBody {
        color: var(--dark-green);
        @extend %body;
        padding-top: 8px;
        padding-bottom: 24px;
    }

    .contactPhone,
    .contactEmail {
        display: block;
        width: fit-content;
        color: var(--dark-green);
        @extend %body;
    }

    .contactHeadline {
        color: var(--green);
        @extend %smallTileHeadline;
    }

    .contactPhone {
        text-decoration: none;
    }

    .downloadCta {
      @extend %buttonRound-lime;
      color: var(--dark-green);
      margin-top: 32px;
    }
}


@media (min-width: 992px) {

    .contactBlock {
        padding-top: 72px;
    }

    .downloadCta {
        margin-top: 40px;
    }

    .contactBody {
        padding-top: 16px;
        padding-bottom: 40px;
    }

}

.reportSection {
    .contactBlock {
        .downloadCta {
            margin-left: auto;
            margin-right: auto;
        }
    }
}