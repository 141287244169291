@import "~scss/imports";

.newsHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid var(--lime);
  padding: 50px 20px;
  margin-bottom: 32px;
  margin-left: 20px;
  margin-right: 20px;

  .bg-header {
    @extend %h1;
  }

  .bg-subheader {
    @extend %smallTileHeadline;
    color: var(--green);
  }
}

.filterWrapper {
    padding: 0px 24px;

}

.filterTitles {
  border-top: 2px solid var(--dark-green);
  color: var(--dark-green);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.filter {
    @extend %secondary-button-text;
    // @extend %focusBlue;
    margin-top: 10px;
    // margin-right: auto;
    @extend %buttonRound-lime;
}

.active.filter {
  background-color: var(--lime);
  color: var(--dark-green);
}

.newsArticlesWrapper {
    padding-top: 48px;
    row-gap: 48px;
    display: grid;
}

.noNewsResults {
    @extend %smallTileHeadline;
    color: var(--green);
    text-align: center;
    margin: 0 auto;
    padding: 50px 0px;
}

.seeMoreBtn {
    @extend %secondary-button-text;
    @extend %focusBlue;
    @extend %buttonRound-lime;
    // background-color: transparent;
    // border: 2px solid var(--green);
    // height: var(--secondary-button-height);
    // color: var(--green);
    margin-top: 48px;
    cursor: pointer;
    width: 100%;
}

.seeMoreBtn:hover {
    border-color: var(--white);
    // background: var(--green);
}

@media (min-width: 768px) {

    .newsArticlesWrapper {
        padding-top: 96px;
        gap: 48px 49px;
        grid-template-columns: repeat(2, 1fr);
    }

    .seeMoreBtn {
        width: auto;
        display: block;
        margin-top: 96px;
        margin-left: auto;
        margin-right: auto;
    }
}


// Desktop Specific Styling
@media (min-width: 992px) {

  .newsHeader {
    padding: 0 0 50px;
    margin: 0 104px;
  }

    .filterWrapper {
      padding: 0px;
      margin: 0 104px;
    }

    .filter {
      margin-top: 32px;
    }

    .newsArticlesWrapper {
      grid-template-columns: repeat(3, 1fr);
  }

    .newsWrapper {
        padding: 112px 0 0px 0;
        margin-bottom: 96px;
    }

    .noNewsResults {
        padding: 200px 0px;
    }

    .filterTitles {
        flex-direction: row;
        border-bottom: none;
    }

}

@media (min-width: 1300px) {
    .newsArticlesWrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}