/* Global Variabl-hes  */

@import url("https://use.typekit.net/uqj0axw.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@300;400&display=swap');

* {
  box-sizing: border-box;
}

:root{
  --alert-height: 105px;
}

/* Smaller devices (landscape phones, 320px and up) */
@media (min-width: 320px) {

  :root{
    --alert-height: 80px;

    --nav-height: 80px;
    --nav-height-collapsed: 70px;
    --report-nav-height: 60px;

    --halfPage-photo-max-width: 288px;

    --related-article-img-height: 160px;

    --body-size: 16px;
    --body-line: 26px;

    --heading-size: 24px;
    --heading-line: 30px;

    --h1-size: 42px;
    --h1-line: 42px;

    --h2-size: 24px;
    --h2-line: 29px;

    --h3-size: 16px;
    --h3-line: 26px;

    --h4-size: 28px;
    --h4-line: 36px;

    --h5-size: 22px;
    --h5-line: 26px;

    --hero-heading-size: 24px;
    --hero-heading-line: 30px;

    --headline-small-size: 18px;
    --headline-small-line: 26px;

    --headline-size: 24px;
    --headline-line: 30px;

    --introBody-size: 16px;
    --introBody-line: 26px;

    --subheader-size: 14px;
    --subheader-line: 22px;

    --primary-button-height: 96px;
    --primary-button-width: 224px;
    --primary-button-size: 16px;
    --primary-button-line: 20px;
    --primary-button-weight: 800;

    --secondary-button-height: 80px;
    --secondary-button-width: 208px;
    --secondary-button-size: 16px;
    --secondary-button-line: 20px;

    --body-small-min-height: 48px;

    --articleCard-max-width: 288px;
    --articleCard-min-height: 514px;

    --nav-card-height: 334px;
  }
}

@media(min-width: 768px) {
  :root{
    --alert-height: 60px;
    --report-nav-height: 75px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  :root{
    --alert-height: 60px;

    --nav-height: 100px;
    --nav-height-collapsed: 80px;

    --report-nav-height: 90px;

    --heading-size: 42px;
    --heading-line: 54px;

    --headline-size: 30px;
    --headline-line: 40px;

    --hero-heading-size: 60px;
    --hero-heading-line: 58px;

    --headline-small-size: 22px;
    --headline-small-line: 30px;

    --h1-size: 76px;
    --h1-line: 76px;

    --h2-size: 48px;
    --h2-line: 58px;

    --h3-size: 20px;
    --h3-line: 26px;

    --related-article-img-height: 210px;

    --introBody-size: 21px;
    --introBody-line: 32px;

    --subheader-size: 18px;
    --subheader-line: 22px;

    --halfPage-photo-max-width: 616px;

    --body-size: 18px;
    --body-line: 30px;

    --primary-button-height: 88px;
    --primary-button-width: 288px;
    --primary-button-size: 16px;
    --primary-button-line: 18px;
    --primary-button-weight: 800;

    --secondary-button-height: 72px;
    --secondary-button-width: 288px;
    --secondary-button-size: 18px;
    --secondary-button-line: 18px;

    --body-small-min-height: 48px;

    --articleCard-max-width: 378px;
    --articleCard-min-height: 616px;

    --nav-card-height: 380px;

  }

}




:root {

  /* Navigation */
  --nav-max-width: 1232px;

  --content-max-width: 1232px;

  /* Photo & Body Copy Sizes */
  --bg-photo-max-width: 1200px;
  --introBody-max-width: 720px;
  --header-bg-max-width: 1040px;
  --inline-photo-max-width: 688px;
  --body-max-width: 720px;
  --header-max-width: 720px;
  --nav-card-min-width: 592px;

  /* Font & Line Height Sizes */

  --body-semi-size: 18px;
  --body-semi-line: 18px;

  --body-medium-size: 18px;
  --body-medium-line: 18px;

  --extra-bold-weight: 800;
  --bold-weight: 700;
  --semi-weight: 600;
  --regular-weight: 500;
  --thin-weight: 400;

  --numbers-size: 14px;
  --numbers-line: 20px;

  --body-small-size: 16px;
  --body-small-line: 26px;


  --footer-link-height: 72px;

  --adelle: 'Roboto Serif', serif;
  --adelle-sans: "acumin-pro", sans-serif;

  --sans: "acumin-pro", sans-serif;

  /* Colors  */
  --black: #000000;
  --blue: #4763D3;
  --dark-green: #172663;
  --white: #ffffff;
  --periwinkle: #F4F6FC;

  --about-primary: #FEE085;
  --about-secondary: #FFFAEB;

  --get-involved-primary: #FF6666;
  --get-involved-secondary: #FFF3F3;

  --services-primary: #EDC9FF;
  --services-secondary: #FCF5FF;

  --news-primary: #16E0BD;
  --donate-primary: #172663;

  --collegeReentry-primary: #FFA35E;
  --collegeReentry-secondary: #FFF6EF;

  --light-green: #ECF2C9;
  --lime: #93C13E;
  --dark-green: #002B00;
  --dark-green-2: #001F00;
  --green: #005B2C;
  --teal: #007B4D;
  --gold: #FFC63E;
  --brass: #C48F0E;
  --wine: #7D113E;
  --orange: #F58220;

  --corner-radius: 8px;
}

/* Global Default Styling */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

body {
  /* margin-top: var(--nav-height); */
  color: var(--dark-green);
}
