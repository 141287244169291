@import "~scss/imports";

.photoTextTable {
  padding: 48px 24px 48px 24px;

  .row:not(:last-child){
    padding-bottom: 48px;
  }

  .heading {
    @extend %h2;
    color: currentColor;
    margin: 0 auto;
    max-width: var(--header-max-width);
    padding-bottom: 48px;
    text-align: left;
  }
}

.photoTextTableInnerWrapper {
  max-width: var(--content-max-width);
  margin: 0 auto;
}

.noImageBorder {
  display: block;
  position: relative;
  margin: 0px;

  img {
      height: auto;
      width: 100%;
      display: block;
  }
}

.imageBorder {
    display: block;
    position: relative;
    margin: 0px;
    border-radius: var(--corner-radius);
    overflow: hidden;

    img {
        height: auto;
        width: 100%;
        display: block;
    }
}

.row {
    display: flex;
    flex-direction: column;
}

.textBlock {
  align-self: flex-start;
    color: currentColor;

    h3 {
        @extend %h4;
        padding-top: 24px;
    }

    p {
        @extend %body;
        padding-top: 8px;
    }

    .cta {
        a {
            margin: 48px auto 0px auto;
        }
    }

}

// Desktop styling
@media (min-width: 992px) {

    .photoTextTable {
        padding: 96px 104px 96px 104px;

        .row:not(:last-child) {
            padding-bottom: 100px;
        }

        .heading {
            padding-bottom: 87px;
        }
    }

    .row:nth-of-type(odd) {
        .textBlock {
            padding-left: 48px;
        }
    }

    .row:nth-of-type(even) {
        flex-direction: row-reverse;

        .textBlock {
            padding-right: 48px;
        }
    }

    .row {
        flex-direction: row;
        align-items: center;
    }

    .textBlock {
        width: 72%;

        h3 {
            padding-top: 0px;
        }

        p {
            padding-top: 24px;
        }

        .cta {
            padding-top: 40px;

            a {
                margin: 0px;
            }
        }
    }

    .noImageBorder {
        width: 66%;
    }

    .imageBorder {
        width: 66%;
    }
}