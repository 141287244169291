%bg-header {
    font-size: var(--heading-size);
    line-height: var(--heading-line);
    color: currentColor;
    margin: 0 auto;
    font-family: var(--sans);
    font-weight: var(--bold-weight);
    text-align: left;
}

%hero-heading {
    font-size: var(--hero-heading-size);
    line-height: var(--hero-heading-line);
    font-family: var(--sans);
    font-weight: var(--bold-weight);
}

%h1 {
  font-size: var(--h1-size);
  line-height: var(--h1-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);
  letter-spacing: -2px;
}

%h2 {
  font-size: var(--h2-size);
  line-height: var(--h2-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);
  letter-spacing: -2px;
}

%introBody {
  font-size: var(--introBody-size);
  line-height: var(--introBody-line);
  font-family: var(--adelle);
  font-weight: var(--thin-weight);
}

%bodySmall {
    font-size: var(--body-small-size);
    line-height: var(--body-small-line);
    font-family: var(--sans);
    font-weight: var(--thin-weight);
}

%h3 {
  font-size: var(--h3-size);
  line-height: var(--h3-line);
  font-family: var(--sans);
  font-weight: var(--bold-weight);
}

%h4 {
  font-size: var(--h4-size);
  line-height: var(--h4-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);
}

%h5 {
  font-size: var(--h4-size);
  line-height: var(--h4-line);
  font-family: var(--sans);
  font-weight: var(--semi-weight);
}

%body {
    font-size: var(--body-size);
    line-height: var(--body-line);
    font-family: var(--adelle);
    font-weight: var(--thin-weight);
}

%bodyBold {
    font-size: var(--body-size);
    line-height: var(--body-line);
    font-weight: var(--bold-weight);
    font-family: var(--sans);
}

%primary-button-text {
    // font-size: var(--primary-button-size);
    // line-height: var(--primary-button-line);
    // font-weight: var(--primary-button-weight);
    // font-family: var(--sans);
    // text-transform: uppercase;
    font-size: var(--body-semi-size);
    line-height: var(--body-semi-line);
    font-family: var(--sans);
    font-weight: var(--regular-weight);
    text-decoration: none;
}

%secondary-button-text {
    font-size: var(--secondary-button-size);
    line-height: var(--secondary-button-line);
    font-family: var(--sans);
    font-weight: var(--regular-weight);
    text-decoration: none;
}

%subheader {
  font-size: var(--subheader-size);
  line-height: var(--subheader-line);
  font-weight: var(--bold-weight);
  font-family: var(--sans);
  text-transform: uppercase;
}

%mobile-nav-links {
    font-size: var(--body-semi-size);
    line-height: var(--body-semi-line);
    font-family: var(--sans);
    font-weight: var(--regular-weight);
}

%numbers {
    font-size: var(--numbers-size);
    line-height: var(--numbers-line);
    font-weight: var(--semi-weight);
    font-family: var(--sans);
}

%headline {
    font-size: var(--headline-size);
    line-height: var(--headline-line);
    font-weight: var(--bold-weight);
    font-family: var(--sans);
}

%smallTileHeadline {
    font-size: var(--headline-small-size);
    line-height: var(--headline-small-line);
    font-family: var(--sans);
    font-weight: var(--bold-weight);
}

%focusWhite {
    &:focus-visible {
        outline-color: var(--white);
        outline-offset: 12px;
        outline-style: solid;
        outline-width: 2px;
    }

    &:focus {
        outline-offset: 10px;
        outline-color: var(--white);
    }
}

%focusBlue {
  &:focus,
  &:focus-visible {
    outline: none;
    background-color: var(--light-green);
  }
}

%buttonRound {
  background-color: transparent;
  border: 2px solid inherit;
  border-radius: 30px;
  display: inline-block;
  font-family: var(--sans);
  padding: 15px 20px;
  transition: all .3s;
  text-decoration: none;

  &:hover {
    background-color: currentColor;
  }
}

%buttonRound-lime {
  // color: var(--white);
  background-color: transparent;
  border-radius: 30px;
  border: 2px solid var(--lime);
  display: inline-block;
  font-family: var(--sans);
  padding: 15px 20px;
  text-decoration: none;
  transition: all .3s;

  &:hover {
    background-color: var(--lime);
    color: var(--dark-green);
  }
}

%breadcrumb {
  font-family: var(--sans);
  font-size: var(--subheader-size);
  line-height: var(--subheader-size);
  font-weight: var(--regular-weight);
}

%icon {
  position: absolute;
  top: 10px;
  right: 16px;
  height: 25px;
  width: 25px;

  svg {
    height: auto;
    width: 100%;
  }

  @media (min-width: 992px) {
    top: 16px;
    right: 24px;
    height: 40px;
    width: 40px;
  }

  &:global(.blog),
  &.blog,
  &:global(.reports),
  &.reports,
  &:global(.news),
  &.news {
    svg * {
      fill: var(--orange);
    }
  }

  &:global(.member),
  &.member {
    svg * {
      fill: var(--green);
    }
  }

  &:global(.page),
  &.page,
  &:global(.think),
  &.think {
    svg * {
      fill: var(--dark-green);
    }
  }

  &:global(.research),
  &.research {
    svg * {
      fill: var(--brass);
    }
  }

  &:global(.events),
  &.events {
    svg * {
      fill: var(--wine);
    }
  }

  &:global(.press),
  &.press {
    svg * {
      fill: var(--wine);
    }
  }
}