@import "~scss/imports";

.featureContainer {
    display: flex;
    justify-content: center;
    padding-top: 96px;
    padding-bottom: 96px;

    img {
        max-width: 600px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mobileFeatureContainer {
    padding: 48px 24px;
}

.featureLink {
    text-decoration: none;
    display: flex;
}

.blogContent {
    padding: 30px 40px 28px 56px;
    max-width: 504px;
    width: 50%;
    background-color: var(--white);
    position: relative;
}

.typeHandle {
    @extend %numbers;
    text-transform: uppercase;
    color: var(--green);
    padding-bottom: 14px;
}

.headline {
    @extend %headline;
    color: var(--dark-green);
    padding-bottom: 24px;
}

.details {
    display: flex;
    border-top: 2px solid var(--dark-green);
    border-bottom: 2px solid var(--dark-green);
    min-height: var(--body-small-min-height);
    align-items: center;

    .date + .publisher {
        padding-left: 24px
    }
}

.date,
.publisher,
.summary,
.authors {
    @extend %bodySmall;
    color: var(--dark-green);
}

.date {
    border-right: 2px solid var(--dark-green);
    padding-right: 22px;
}

.featurePostArrow {
    position: absolute;
    bottom: 28px;
    margin: 0;
}

.authors {
    display: flex;
    border-bottom: 2px solid var(--dark-green);
    min-height: var(--body-small-min-height);
    align-items: center;
    font-style: italic;
}

.summary {
    padding-top: 24px;
    padding-bottom: 36px;
}


