@import "~scss/imports";

.inlineMatrixWrapper {
    padding: 48px 24px;
    margin: 0 auto;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.inlineMatrixWrapper > :first-child {
    margin-top: 0px;
}

.noImageBorder {
    max-width: var(--inline-photo-max-width);
    margin: 48px auto 0px auto;
}

.imageBorder {
    display: block;
    position: relative;
    border-radius: var(--corner-radius);
    overflow: hidden;
    margin: 0 auto;
    max-width: var(--inline-photo-max-width);
}

.inlineHeader {
    margin-top: 48px;
    max-width: var(--introBody-max-width);
    @extend %bg-header;
}

.inlineSubheader {
    @extend %h3;
    color: currentColor;
    max-width: var(--introBody-max-width);
    margin: 48px auto 0px auto;
}

.inlineBody{
    max-width: var(--introBody-max-width);
    margin: 16px auto 0px auto;
}

.inlineCta {
  // margin-top: 48px;
  max-width: var(--introBody-max-width);
  margin: 16px auto 0px auto;
}

.inlineCta + .inlineBody {
  margin-top: 48px;
}

@media (min-width: 992px) {

  // .inlineMatrixWrapper {
  //   + footer {
  //     margin-top: 104px;
  //   }
  // }

  .inlineMatrixWrapper > .noImageBorder + .inlineBody,
  .inlineMatrixWrapper > .imageBorder + .inlineBody {
    margin-top: 64px;
  }

  .inlineHeader,
  .inlineSubheader,
  .inlineCta,
  .imageBorder,
  .noImageBorder {
    margin-top: 96px;
  }

  .inlineBody {
    margin-top: 32px;
  }

  .inlineCta + .inlineBody {
    margin-top: 96px;
  }
}