@import "~scss/imports";

.element {
  // display: flex;
  // flex-direction: column;
  // display: grid;
  // grid-template-columns: 1fr;
  padding: 48px 24px;
  // grid-gap: 50px;

  > div {
    padding: 0;
  }

  @media (min-width: 1024px) {
    // grid-template-columns: 1fr 1fr 1fr;
    padding: 96px 104px;
  }


  &:global(.bg--white) {
    background-color: var(--white);
    color: var(--dark-green);
  }
  &:global(.bg--lightGreen) {
    background-color: var(--light-green);
    color: var(--dark-green);
  }
  &:global(.bg--green) {
    background-color: var(--teal);
    color: var(--white);
  }

  &:global(.bg--lime) {
    background-color: var(--lime);
    color: var(--dark-green);
  }

  &:global(.bg--gold) {
    background-color: var(--gold);
    color: var(--dark-green);
  }

  &:global(.bg--wine) {
    background-color: var(--wine);
    color: var(--white);

    :global(.primary) {
      border-color: var(--white);
      color: var(--white);

      &:hover {
        background-color: var(--white);
        color: var(--wine);
      }
    }
  }
}