@import "~scss/imports";

.home,
.donate {
    background: var(--periwinkle)
}

.whoWeAre {
    background: var(--about-secondary);
}

.getInvolved {
    background: var(--get-involved-secondary);
}

.findSupport {
    // background: var(--services-secondary);
    background-color: var(--light-green);
}

.standardWhiteBackground {
    background: var(--white);
}

.donate {
    background: var(--donate-secondary);
}

.collegeReentry {
  background: var(--collegeReentry-secondary);
}

.lightGreen {
  background-color: var(--light-green);
  color: var(--dark-green);
}

.green {
  background-color: var(--teal);
  color: var(--white);
}

.lime {
  background-color: var(--lime);
  color: var(--dark-green);
}

.gold {
  background-color: var(--gold);
  color: var(--dark-green);
}

.wine {
  background-color: var(--wine);
  color: var(--white);
}