@import "~scss/imports";


.mapSection {
  padding: 48px 24px;

  @media (min-width: 992px) {
    padding: 96px 104px;
  }
}

.mapHeader {
  @extend %h2;
  max-width: var(--introBody-max-width);
  margin: 0 auto 0 auto;
  text-align: center;
}

.mapDescription {
  margin: 0 auto 0 auto;
  max-width: var(--introBody-max-width);
}

.mapHeader + .mapDescription {
  margin-top: 1rem;;
}


.tooltip {
  margin: 0!important;
  padding: 0!important;
  background-color: var(--dark-green)!important;
  border-radius: var(--corner-radius)!important;
  opacity: 1!important;
}

.tooltipContent {
  padding: 32px!important;
  max-width: 400px;

  .tooltipHeading {
    @extend %h3;
    color: var(--lime);
    padding-bottom: 16px;
  }

  .tooltipRichText {
    p {
      @extend %bodySmall;
      color: var(--white);
    }

    a {
      color: var(--lime);
    }
  }
}