@import "~scss/imports";

.articlesWrapper {
    padding: 48px 24px 48px 24px;

    .articleCard {
        a {
            @extend %focusWhite;
        }
    }
}


.articleCard {
  position: relative;
  filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.15));
  border-radius: var(--corner-radius);

    &:global(.newsCard) {

        a {
            @extend %focusBlue;
        }
    }
}


.icon {
  @extend %icon;
}


.articlesInnerWrapper {
    max-width: var(--content-max-width);
    margin: 0 auto;
}

.articlesHeader {
    padding-bottom: 48px;
    color: currentColor;

    h3 {
        @extend %h4;
        text-align: center;
    }

    h3 + p {
        padding-top: 8px;
    }

    p {
        @extend %bodySmall;
    }
}

.cardWrapper {
    row-gap: 48px;
    display: grid;
}

.singleCard {
    margin: 0 auto;
}

.articleCard,
.singleCard {
    min-height: var(--articleCard-min-height);
    background-color: var(--white);
    border-radius: var(--corner-radius);
    filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.15));

    a {
        text-decoration: none;
        color: var(--dark-green);
        display: grid;
        height: 100%;
    }
}

.cardInner {
    display: grid;
    grid-template-areas:
    "top"
    "image"
    "title"
    "filler"
    "date"
    "publisher"
    "author";

    grid-template-rows: auto auto auto 1fr auto auto auto;

}

.articleCard:last-child {
    margin-bottom: 0px;
}

.cardTop {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;

    p {
      @extend %numbers;
      font-size: 16px;
      letter-spacing: 0;
      color: var(--green);
    }
}

.articleImg {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.articleImageWrapper {
    grid-area: image;
    height: var(--related-article-img-height);
    overflow: hidden;
}

.cardTitle,
.cardDate,
.cardPublisher,
.cardAuthor {
    margin: 0px 24px;
}

.cardTitle {
    grid-area: title;
    @extend %smallTileHeadline;
    min-height: 150px;
    padding: 16px 0px;
}

.cardAuthor,
.cardDate,
.cardPublisher {
  @extend %bodySmall;
  align-items: center;
  border-top: 2px solid var(--lime);
  display: flex;
  min-height: var(--body-small-min-height);
}

.cardDate {
  grid-area: date;
}

.cardPublisher {
  grid-area: publisher;
}

.cardAuthor {
  grid-area: author;
}


// Medium Sized devices
@media (min-width: 768px) and (max-width: 991px) {

    .cardWrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 48px 49px;
    }

    .singleCard {
        width: 53%;
    }

}

// Large Sized Devices and Up
// Desktop Styling
@media (min-width: 992px) {

    .cardWrapper {
        gap: 48px 49px;
        grid-template-columns: repeat(3, 1fr);
    }

    .articlesWrapper {
        padding: 96px 104px 96px 104px;
    }

    .articlesHeader {
        padding-bottom: 96px;

        h3 + p {
            padding-top: 32px;
        }

        p {
            font-size: var(--body-size);
            line-height: var(--body-line);
        }

    }

    .cardTop {
        padding: 24px 24px 30px;
    }
}

//Extra large Devices uses 3 columns
@media (min-width: 1300px) {
    .cardWrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}