@import "~scss/imports";

.blueText {
    p,
    strong,
    ul,
    li,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: currentColor;
    }

    a {
        @extend %focusBlue;
        color: currentColor;
        text-decoration: none;
        transition: border-bottom .3s;
        border-bottom: 1px solid currentColor;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }
}

.whiteText {
    p,
    strong,
    ul,
    li,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--white);
    }

    a {
        @extend %focusWhite;
        color: currentColor;
        text-decoration: none;
        transition: border-bottom .3s;
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid #C3FAF0;
        }
    }


}

.richTextField {
    p,
    strong,
    a,
    ul,
    li,
    ol {
        @extend %body;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--adelle-sans);
    }

    strong {
        @extend %bodyBold;
    }

    blockquote {
        @extend %smallTileHeadline;
        line-height: 32px;
        color: currentColor;
        border-top: 2px solid var(--lime);
        border-bottom: 2px solid var(--lime);
        padding: 24px 0px;
        margin: 48px 0px;
    }

    p + p {
        padding-top: 32px;
    }

    h3 + p {
      margin-top: 1rem;
    }

    figure {
        margin: 0px;
    }

    img {
      display: block;
      height: auto;
      max-width: 100%;
      width: auto;
    }

    figure {
        iframe {
            max-width: var(--body-max-width);
            width: 100%!important;
        }
    }
}


@media (min-width: 992px) {

    .richTextField {

        blockquote {
            padding: 40px 0px;
            margin: 72px 0px;
        }
    }
}