@import "~scss/imports";

.headerWrapper {
  padding: 48px 24px;
  color: var(--dark-green);
  grid-gap: 16px;

  display: grid;
  grid-template-areas:
    "breadcrumb"
    "heading"
    "subheading"
    "introCopy"
    "body"
  ;
  grid-template-columns: 1fr;

  @media (min-width: 992px) {
    grid-template-areas:
    "breadcrumb breadcrumb"
    "heading subheading"
    "introCopy body"
  ;
  grid-template-columns: 1fr 1fr;
  }
}

.headerWrapper.specialReportHeader {
  .headerBreadcrumb {
    border-bottom: none;
  }

  .headerBreadcrumb+.headerIntroBody {
    margin-top: 0px;
  }
}

.headerWrapper.borderBottom {
  .headerHeading {
    position: relative;

    &:before {
      background-color: var(--lime);
      content:"";
      height: 2px;
      left: 50%;
      position: absolute;
      top: calc(100% + 16px);
      bottom: 0;
      transform: translateX(-50%);
      width: calc(100vw - 48px);
    }
  }
}

.headerHeading {
  @extend %h1;
  grid-area: heading;
}

.headerBreadcrumb {
  @extend %breadcrumb;
  display: inline-block;
  grid-area: breadcrumb;
  border-bottom: 2px solid var(--lime);
  justify-self: flex-start;
  padding-bottom: 2px;
}

.headerIntroBody {
  @extend %smallTileHeadline;
  align-self: flex-start;
  color: var(--green);
  grid-area: introCopy;
  text-align: left;
}

.headerSubhead {
  @extend %smallTileHeadline;
  color: var(--green);
  grid-area: subheading;
}

.headerBody {
  @extend %smallTileHeadline;
  align-self: flex-start;
  color: var(--green);
  grid-area: body;
  position: relative;
  text-align: left;

  a,
  p,
  li {
    @extend %smallTileHeadline;
    color: var(--green);
  }
}

.headerCTA {
  grid-area: introCopy;
  justify-self: flex-start;

  @media (min-width: 992px) {
    grid-area: subheading;
    margin-top: 48px;
  }
}

@media (min-width: 992px) {
  .headerWrapper {
    grid-gap: 60px;
    padding: 96px 104px;
  }

  .headerWrapper.borderBottom .headerHeading {
    &:before {
      background-color: var(--lime);
      content:"";
      height: 2px;
      left: calc(100% + 15px);
      position: absolute;
      top: calc(100% + 30px);
      width: calc(200% + 30px);
    }
  }


  .headerIntroBody {
    max-width: var(--introBody-max-width);
  }

}