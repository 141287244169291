@import "~scss/imports";

.contentWrapper {
    max-width: var(--body-max-width);
    margin-left: auto;
    margin-right: auto;
    padding: 0px 24px;

    >a {
        text-decoration: none;
    }
}

.typeHandle {
  display: flex;
  align-items: center;
  text-decoration: none;
  grid-gap: 1rem;

  &:hover {
    // .typeName {
    //   border-color: var(--white);
    // }

    svg {
      left: -30px;
    }
  }

  @media (min-width: 992px) {
    position: relative;

    svg {
      position: absolute;
      left: -24px;
      top: 2px;
      transition: all 0.2s;
    }
  }
}


.typeName {
  @extend %breadcrumb;
  color: var(--dark-green);
  display: inline-block;
  border-bottom: 2px solid var(--lime);
  padding-bottom: 4px;
  transition: border 0.2s;
  text-decoration: none;
}

.articleHeader {
    @extend %h2;
    color: var(--dark-green);
}

.details {
    border-top: 2px solid var(--lime);
    border-bottom: 2px solid var(--lime);
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    min-height: var(--body-small-min-height);
}

.date, .publisher {
    @extend %bodySmall;
    min-height: var(--body-small-min-height);
    display: flex;
    align-items: center;
    color: var(--dark-green);
}

.date {
    border-bottom: 2px solid var(--lime);
}

.authors {
    display: flex;
    border-bottom: 2px solid var(--lime);
    color: var(--dark-green);
    min-height: var(--body-small-min-height);
    align-items: center;
    font-style: italic;
}

.articleContentWrapper {
    margin: 0px auto 48px auto;
}

.blogImage {

    img {
        height: auto;
        width: 100%;
        display: block;
    }
}

.imageBorder {
    display: block;
    margin: 48px 0px 0px 0px;
    position: relative;
    max-width: var(--inline-photo-max-width);
    overflow: hidden;
    border-radius: var(--corner-radius);
}

.caption {
    color: var(--dark-green);
    @extend %bodySmall;
    padding-top: 24px;
}

.articleContent {
    padding-top: 48px;
}

.blogBtn {
    margin: 48px auto 0px auto;
}

@media (min-width: 992px) {

    .contentWrapper {
        padding-top: 112px;
    }

    .articleContent {
        padding-top: 56px;
    }

    .blogBtn {
        margin-top: 56px;
    }

    .details {
        border-top: 2px solid var(--lime);
        border-bottom: 2px solid var(--lime);
        flex-direction: row;
        min-height: var(--body-small-min-height);
        align-items: center;
    }

    .authors {
        display: flex;
        border-bottom: 2px solid var(--lime);
        min-height: var(--body-small-min-height);
        align-items: center;
        font-style: italic;
    }

    .date, .publisher {
        min-height: auto;
    }

    .date {
        border-right: 2px solid var(--lime);
        padding-right: 22px;
        border-bottom: none;
    }

    .publisher {
        padding-left: 24px;
    }

    .imageBorder {
        margin-top: 56px;
        padding: 15px;

        &:before {
            right: 15px;
            left: 15px;
        }

        &:after {
            top: 15px;
            bottom: 15px;
        }
    }

    .articleContentWrapper {
        margin: 0px auto 96px auto;
    }
}

