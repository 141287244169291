@import "~scss/imports";

.contactPageWrapper {

    h1 {
        max-width: var(--header-max-width);
        @extend %bg-header;
    }

    .contactPageIntroBody {
        max-width: var(--introBody-max-width);
        margin: 0 auto;
        padding-top: 16px;
        color: var(--dark-green);
        @extend %introBody;
    }

    padding: 0px 24px 48px 24px;
}

@media (min-width: 992px) {

    .contactPageWrapper {
        padding: 112px 0px 96px 0px;

        .contactPageIntroBody {
            padding-top: 32px;
        }
    }
}
