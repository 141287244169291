@import "~scss/imports";

.heroImage {
    position: relative;
    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
